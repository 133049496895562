var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-navigation-drawer',{attrs:{"width":"400","right":"","temporary":"","app":""},model:{value:(_vm.localDrawer),callback:function ($$v) {_vm.localDrawer=$$v},expression:"localDrawer"}},[_c('v-list-item',{class:_vm.localAula.color},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.localAula.tipo_de_aula))])],1)],1),_c('v-divider'),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Professor (a)")]),_c('p',{staticClass:"font-weight-light text-justify"},[_vm._v(" "+_vm._s(_vm.localAula.professor?.nome)+" ")]),_c('v-divider'),_c('v-list-item-title',[_vm._v("Data")]),_c('p',{staticClass:"font-weight-light text-justify"},[_vm._v(" "+_vm._s(_vm.localAula.data_formatada)+" "+_vm._s(" - ")+" "+_vm._s(_vm.localAula.horario ? _vm.localAula.horario.descricao : "[- - -]")+" ")]),(_vm.disciplina)?[_c('div',{staticClass:"mt-4 mb-0"},[_c('v-list-item-title',[_c('strong',[_vm._v(" Disciplina:")]),_vm._v(" "+_vm._s(_vm.disciplina.descricao)+" ")])],1)]:[_c('div',{staticClass:"mt-4 mb-5"},[_c('v-list-item-title',[_vm._v("Disciplina(s)")])],1),_c('div',_vm._l((_vm.localAula.disciplinas_com_descricao),function(disciplina,index){return _c('p',{key:index,staticClass:"font-weight-light text-justify"},[_vm._v(" "+_vm._s(disciplina?.descricao ? disciplina?.descricao : "- - -")+" ")])}),0)],_vm._l((_vm.$constants.listCamposAulasLancadas),function(item,index){return _c('div',{key:index},[(index !== 'campos_de_experiencias')?_c('div',[(
              _vm.localAula[index] ||
              (index === 'conteudo_polivalencia' && _vm.localAula?.disciplinas?.length > 0)
            )?[(
                (index !== 'conteudo_polivalencia' && _vm.localAula[index]) ||
                (index === 'conteudo_polivalencia' &&
                  _vm.localAula.disciplinas.length > 0 &&
                  _vm.formatTexto(_vm.localAula.conteudo_polivalencia))
              )?_c('v-list-item-title',{staticClass:"mt-4 mb-5"},[_vm._v(" "+_vm._s(item)+" ")]):_vm._e(),(
                (index !== 'conteudo_polivalencia' && _vm.localAula[index]) ||
                (index === 'conteudo_polivalencia' &&
                  _vm.localAula.disciplinas.length > 0 &&
                  _vm.formatTexto(_vm.localAula.conteudo_polivalencia))
              )?_c('div',{staticClass:"font-weight-light text-justify"},[_c('div',{domProps:{"innerHTML":_vm._s(
                  index === 'conteudo_polivalencia' && _vm.localAula.disciplinas.length > 0
                    ? _vm.formatTexto(_vm.localAula.conteudo_polivalencia)
                    : _vm.formatTexto(_vm.localAula[index])
                )}})]):_vm._e()]:_vm._e()],2):_c('div',[(_vm.localAula[index])?_c('v-list-item-title',{staticClass:"mt-4 mb-2"},[_vm._v(" "+_vm._s(item)+" ")]):_vm._e(),_vm._l((_vm.localAula[index]),function(el,idx){return _c('div',{key:idx},[(el)?_c('p',{staticClass:"font-weight-light text-justify"},[_vm._v("• "+_vm._s(el))]):_vm._e()])})],2)])}),_c('v-divider'),_c('v-divider'),_c('v-list-item-title',{staticClass:"mt-4"},[_vm._v("Situação")]),_c('v-list-item-title',[_c('v-chip',{attrs:{"label":"","color":_vm.localAula.color}},[_vm._v(" "+_vm._s(_vm.localAula.descricaoSituacao)+" ")])],1),_c('v-list-item-title',{staticClass:"mt-4"},[_vm._v("Motivo")]),_c('p',{staticClass:"font-weight-light"},[_vm._v(" "+_vm._s(_vm.localAula.motivo ? _vm.localAula.motivo : "Sem motivo.")+" ")])],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }