<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="() => $router.back()"
            :disabled="submittingForm"
          >
            <v-icon left> fa fa-angle-left </v-icon> </v-btn
          >Pedido de Autorização
        </h1>
      </v-col>
    </v-row>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submitForm)" :disabled="submittingForm">
        <v-row>
          <v-col class="pt-0 pb-0" cols="2">
            <e-label>Etapa</e-label>
            <p>{{ etapaInfo.descricao }}</p>
          </v-col>
          <v-col class="pt-0 pb-0" cols="4">
            <ValidationProvider name="Solicitação" rules="required" v-slot="{ errors }">
              <e-label>Solicitação</e-label>

              <e-autocomplete
                :items="pedidos"
                :return-object="false"
                :error-messages="errors"
                :item-text="(item) => item.descricao"
                :item-value="(item) => item.id"
                v-model="formAutorizacao.pedido_id"
                label="Selecione um tipo"
                dense
                solo
              />
            </ValidationProvider>
          </v-col>
          <v-col class="pt-0 pb-0" cols="6">
            <ValidationProvider name="Avaliador" rules="required" v-slot="{ errors }">
              <e-label>Avaliador</e-label>

              <e-autocomplete
                :items="outrosUsuariosList"
                :return-object="false"
                :error-messages="errors"
                :item-text="(item) => item.name"
                :item-value="(item) => item.id"
                v-model="formAutorizacao.user_aprovador"
                label="Selecione um Usuário"
                dense
                solo
              />
            </ValidationProvider>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="pt-0 pb-0" cols="12">
            <ValidationProvider name="Descrição" rules="" v-slot="{ errors }">
              <e-label>Observações</e-label>
              <v-textarea
                v-on:change="(value) => deixarLetraMinuscula(value, 'observacoes')"
                :error-messages="errors"
                v-model="formAutorizacao.observacoes"
                dense
                solo
              />
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-btn
              color="primary"
              :disabled="submittingForm"
              :loading="submittingForm"
              type="submit"
              block
              >Enviar Dados</v-btn
            >
          </v-col>
        </v-row>
      </form>
    </ValidationObserver>
  </main-template>
</template>

<style></style>

<script>
import { mapActions, mapGetters } from "vuex";
import store from "@/store";
import ELabel from "../../../components/ELabel.vue";
import { dateFormatParam } from "../../../plugins/filters";
import Usuario from "../../../Models/User";

export default {
  components: { ELabel },
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters("Autorizacoes", ["autorizacoes", "autorizacoesList"]),
    ...mapGetters("Etapas", ["etapas", "etapasList"]),
    formAutorizacao() {
      if (!this.autorizacoes || !this.editing) return {};
      const { autorizacao_id } = this.$route.params;
      const autorizacao = this.autorizacoesList
        .filter((cir) => cir.id === parseInt(autorizacao_id, 10))
        .shift();
      return { ...autorizacao };
    },
  },
  mounted() {
    this.loadData();
    this.carregarUsuarios(1);
    this.verificarEtapa();
  },
  data() {
    return {
      etapaInfo: [],
      outrosUsuariosList: [],
      on: null,
      attrs: null,
      data_inicial: null,
      data_final: null,
      modalInicio: false,
      modalFim: false,
      pedidos: [
        {
          id: 1,
          descricao: "LANÇAR AULAS FORA DO PERIODO",
        },
      ],
      users: [
        {
          id: 1,
          name: "pedro",
        },
        {
          id: 2,
          name: "joao",
        },
      ],
      autorizacaoId: null,
      submittingForm: false,
    };
  },
  watch: {
    data_inicial(value) {
      const data_old = new Intl.DateTimeFormat("pt-BR", { timeZone: "UTC" }).format(
        new Date(value)
      );
      this.formAutorizacao.tempo_inicial = data_old.replaceAll("/", "/");
    },
    data_final(value) {
      const data_old = new Intl.DateTimeFormat("pt-BR", { timeZone: "UTC" }).format(
        new Date(value)
      );
      this.formAutorizacao.tempo_final = data_old.replaceAll("/", "/");
    },
  },
  methods: {
    ...mapActions("Autorizacoes", ["loadAutorizacoes"]),
    verificarEtapa() {
      this.etapaInfo = this.etapasList.filter((d) => d.id === this.$route.params.etapa).shift();
    },
    setCircuito(autorizacao) {
      this.autorizacaoId = autorizacao.id;
    },
    async carregarUsuarios(pageNumber, query = null) {
      try {
        const response = await this.$services.autorizacoesService.getUsuarioTi(pageNumber, query);

        const usuarios = Array.isArray(response.data)
          ? response.data.map((usuario) => new Usuario(usuario))
          : [new Usuario(response.data)];
        this.outrosUsuariosList = usuarios;
      } catch (error) {
        this.$handleError(error);
      }
    },
    deixarLetraMinuscula(value, campo) {
      const novo_valor = value.toLowerCase().split(" ");
      const valor = novo_valor
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
      this.formAutorizacao[campo] = valor;
    },
    async submitForm() {
      const { etapa } = this.$route.params;
      this.submittingForm = true;
      this.formAutorizacao.status = "PENDENTE";
      const currentUser = store.getters["Auth/user"];
      this.formAutorizacao.user_solicitante = currentUser.id;
      this.formAutorizacao.etapa_id = etapa;
      try {
        if (this.editing) {
          await this.$services.autorizacoesService.atualizarAutorizacao(this.formAutorizacao);
        } else {
          await this.$services.autorizacoesService.criarAutorizacao(this.formAutorizacao);
        }
        this.$toast.success("Pedido enviado com sucesso");
        this.$router.back();
      } catch (error) {
        this.$handleError(error);
      }
      this.submittingForm = false;
    },
    async loadData() {
      try {
        if (this.autorizacoes.length === 0) {
          this.$loaderService.open("Carregando dados");
          await Promise.all([this.loadAutorizacoes()]);
          this.$loaderService.close();
        }
        this.$forceUpdate();
      } catch (error) {
        this.$handleError(error);
      }
    },
  },
};
</script>

<style></style>
